<template>
  <div class="page-container">
    <h1 @click="showChoosePerson = true">showChoosePerson</h1>
    <h1 @click="showChooseDepart = true">showChooseDepart</h1>
    <ChoosePerson
      v-if="showChoosePerson"
      :options="choosePersonOpts"
    ></ChoosePerson>
    <ChooseDepart
      v-if="showChooseDepart"
      :options="chooseDepartOpts"
    ></ChooseDepart>
    <h1>Hello Welcome {{ user ? user.username : "" }} {{ genderText }}</h1>
    <router-link to="/test" class="app-float-btn round-999"
      >go page test</router-link
    >
    <button @click="doLogout">注销</button>
    <checkbox v-model="singleCheck" value="勾选协议" label="我来啦"></checkbox>

    <checkbox v-model="arr" value="大乔" type="custom" color="#f00"></checkbox>
    <checkbox v-model="arr" value="小乔" color="#990"></checkbox>
    <checkbox v-model="arr" value="凤姐"></checkbox>

    <checkbox
      v-model="arr2"
      value="xs"
      label="西施"
      color="#990"
      :custom-style="checkBoxCustomStyle"
    ></checkbox>
    <checkbox v-model="arr2" value="dc" label="貂蝉" color="#990"></checkbox>

    <radio v-model="isAdult" value="0" label="否" color="#990"></radio>
    <radio v-model="isAdult" value="1" label="是" type="success"></radio>
    <radio v-model="isAdult" value="-1" label="未知" disabled></radio>
    <radio v-model="isAdult" value="-2" label="未知2" disabled></radio>
    <radio
      v-model="isAdult"
      value="-3"
      label="未知3"
      disabled
      color="#990"
    ></radio>
    <radio
      v-model="isAdult"
      value="-4"
      label="未知4"
      disabled
      :custom-style="checkBoxCustomStyle"
    ></radio>
    <radio
      v-model="isAdult2"
      value="-5"
      label="未知5"
      color="red"
      :custom-style="checkBoxCustomStyle"
    ></radio>
    <radio
      v-model="isAdult2"
      value="-6"
      label="未知6"
      :custom-style="checkBoxCustomStyle2"
    ></radio>
    <br />
    <div>
      <span
        class="app-float-btn app-round-999"
        style="color: #fff; background-color: blue; padding: 3px 5px"
        >注销4</span
      >
      <span
        class="app-float-btn"
        style="color: #fff; background-color: #f44336; padding: 3px 5px"
        >注销4</span
      >
    </div>
    <br /><br /><br />
    <div>
      <span>前面的文字</span>
      <Radio v-model="radioField" label="我已阅读并同意" color="red" value="1"
        ><span style="color: green; font-weight: bold">追加的slot</span></Radio
      ><span>《大数据门户系统服务协议》及《数据安全 使用承诺书》</span>
      <Radio v-model="radioField" label="我不同意" value="0"
        ><span style="color: green; font-weight: bold">追加的slot2</span></Radio
      >
      <br />
      <Radio
        v-model="radioField"
        label="东"
        value="东"
        :custom-style="{ fs: '12px', size: '12px' }"
      ></Radio
      ><br />
      <Radio
        v-model="radioField"
        label="南"
        value="南"
        :custom-style="{ fs: '14px', size: '14px' }"
      ></Radio
      ><br />
      <Radio
        v-model="radioField"
        label="西"
        value="西"
        :custom-style="{ fs: '16px', size: '16px' }"
      ></Radio
      ><br />
      <Radio
        v-model="radioField"
        label="北"
        value="北"
        :custom-style="{ fs: '32px', size: '32px' }"
      ></Radio
      ><br />
    </div>
    <h1 @click="showCascader = true">show cascader</h1>
    <div>
      <Cascader
        multiple
        :options="casOpts"
        :selected-path="[]"
        color="#009688"
        v-if="showCascader"
        @cancel="showCascader = false"
        @confirm="confirmCascader"
      ></Cascader>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import ChoosePerson from "@/components/ChoosePerson/ChoosePerson";
import ChooseDepart from "@/components/ChooseDepart/ChooseDepart";
import Checkbox from "@/components/Checkbox";
import Radio from "@/components/Radio";
import Cascader from "@/components/Cascader/Cascader";
import axios from "axios";
// import initSqlJs from 'sql.js';
// import localForage from "localforage";
// import PreviewImage from '../common/plugins/PreviewImage'

export default {
  name: "test",
  components: {
    Checkbox,
    Radio,
    ChoosePerson,
    ChooseDepart,
    Cascader,
  },
  data() {
    return {
      showCascader: false,
      casOpts: [
        {
          text: "天府新谷",
          value: "天府新谷",
          children: [
            {
              text: "10栋",
              value: "10栋",
              children: [
                {
                  text: "1001",
                  value: "1002",
                },
                {
                  text: "1010",
                  value: "1011",
                },
              ],
            },
            {
              text: "11栋",
              value: "11栋",
              children: [
                {
                  text: "1101",
                  value: "1102",
                },
                {
                  text: "1187",
                  value: "1192",
                },
              ],
            },
            {
              text: "18栋",
              value: "18栋",
              children: [
                {
                  text: "1801",
                  value: "1802",
                },
                {
                  text: "1808",
                  value: "1823",
                },
              ],
            },
          ],
        },
        {
          text: "财富国际",
          value: "财富国际",
          children: [
            {
              text: "1701",
              value: "1701",
            },
            {
              text: "1010",
              value: "1010",
            },
          ],
        },
      ],
      choosePersonOpts: {
        color: "#abcdef",
        headerHeight: "45px",
        itemHeight: "40px",
        footerHeight: "50px",
        //type: 'single'
      },
      showChoosePerson: false,
      chooseDepartOpts: {
        color: "#da5961",
        // headerHeight: '45',
        // itemHeight: '75px',
        // footerHeight: '90px',
        levelPadding: 15,
        type: "multiple",
      },
      showChooseDepart: false,
      radioField: "",
      singleCheck: true,
      arr: ["凤姐"],
      arr2: ["xs", "dc"],
      arr2Label: ["西施", "貂蝉"],
      checkBoxCustomStyle: {
        colorDefault: "#ce9898",
        fs: "40px",
        size: "64px",
      },
      checkBoxCustomStyle2: {
        colorDefault: "#ce9898",
        fs: "16px",
        size: "20px",
      },
      isAdult: -4,
      isAdult2: -5,
      gender: 2,
    };
  },
  created() {
    this.getMenu();
  },
  mounted() {
    // PreviewImage({
    //     url: 'https://img2.baidu.com/it/u=1835843610,1575206394&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1661446800&t=6dbc55826bb998465cf8f8df02190d4c',
    //     urls: [
    //         'https://img2.baidu.com/it/u=1835843610,1575206394&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1661446800&t=6dbc55826bb998465cf8f8df02190d4c',
    //         'https://img1.baidu.com/it/u=1181391565,2210118181&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661446800&t=51d99b6f83303c0a932b3cb8061e0ebf',
    //         'https://img2.baidu.com/it/u=1204473175,3676263147&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661446800&t=0fe61dfa8ee1dee237d641c82663e8ad',
    //         'https://img0.baidu.com/it/u=3814667313,3000795201&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661446800&t=52e6cb5304bface9130f66752ccab2ba'
    //     ]
    // });
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["genderText"]),
  },
  methods: {
    ...mapMutations(["logout"]),
    ...mapActions(["getMenu"]),
    login() {
      this.$request({
        url: "/auth/login",
      });
    },
    refreshToken() {
      this.$request.post("auth/refresh-token");
    },
    doLogout() {
      let yes = confirm("确定退出?");
      if (yes) {
        this.logout();
        this.$router.push("/login");
      }
    },
    confirmCascader(data) {
      this.showCascader = false;
      console.log("data", JSON.stringify(data));
    },
  },
};
</script>

<style lang="scss">
// 自定义函数
@function px2vw($px, $design-width: 750) {
  @return #{$px * 100 / $design-width}vw;
}

// 使用函数
.page-container-test {
  width: px2vw(375); // 输出50vw
}
</style>