<template>
    <div class="component-container--choose-depart-item">
        <template v-if="!item.children || item.children.length == 0">
            <div flex="cross:center" class="final-item" :style="itemStyle(item)" :class="onClass(item)" @click="select(item)">
                <img :src="item.icon" alt="">
                <span>{{item.name}}</span>
            </div>
        </template>
        <template v-else>
            <div class="item-wrapper">
                <div flex="cross:center" class="item" :style="style" :class="collapse ? '' : 'on'" @click="collapse = !collapse">
                    <img :src="item.icon" alt="">
                    <span>{{item.name}}</span>
                </div>
                <div class="action" flex="cross:center" v-show="calcCanSelectAll()">
                    <Checkbox v-model="selectAll" label="全选此级" :color="options.color"></Checkbox>
                </div>
            </div>
            <transition name="fade">
                <div class="choose-depart-item-sub" v-show="!collapse">
                    <ChooseDepartItem v-for="(v, i) in item.children" :key="i" :item="v" :index="i" :item-selected="itemSelected" :level="nextLevel" :options="options" @select="select" @select-all="selectItemAll" @update-children="updateChildren" :autoLoad="!collapse"></ChooseDepartItem>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
    import Checkbox from '../Checkbox.vue';

    export default {
        name: "ChooseDepartItem",
        props: {
            item: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true  
            },
            itemSelected: {
                type: Array,
                required: true,
            },
            level: {
                type: Number,
                default: 0
            },
            autoLoad: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Object,
                default() {
                    return {
                        color: '#52994F',
                    }
                }
            }
        },
        data() {
            return {
                isGetData: false,
                collapse: true,
                selectAll: false,
                canEmitSelectAll: true,
            }
        },
        computed: {
            nextLevel() {
                return this.level + 1;
            },
            style() {
                return {
                    paddingLeft: 10 + (this.options.levelPadding * this.level) + 'px',
                    height: this.options.itemHeight
                }
            },
            actionStyle() {
                let style = {
                    height: this.options.itemHeight,
                    paddingLeft: 10 + (this.options.levelPadding * this.nextLevel) + 'px',
                    borderBottom: '1px solid #eee'
                };
                return style;
            }
        },
        watch: {
            itemSelected(selectedArr) {
                if(!this.item.children || this.item.children.length == 0 || this.options.type != 'multiple') {
                    return;
                }
                let tmpArr = [];
                this.item.children.forEach(v => {
                    if(!v.children || v.children.length ==0) {
                        tmpArr.push(v);
                    } 
                });

                let isSelectAll = true;
                for(let i = 0; i < tmpArr.length; i++) {
                    if(!selectedArr.includes(tmpArr[i]['id'])) {
                        isSelectAll = false;
                    }
                }

                if(!isSelectAll) {
                    if(this.selectAll) {
                        this.canEmitSelectAll = false;
                        this.selectAll = false;   
                    }
                } else {
                    if(!this.selectAll) {
                        this.canEmitSelectAll = false;
                        this.selectAll = true;   
                    }
                }
            },
            autoLoad(val) {
                if(val) {
                    this.getData();
                }
            },
            selectAll(newVal, oldVal) {
                if(this.canEmitSelectAll) {
                    this.$emit('select-all', this.item, newVal);   
                } else {
                    this.canEmitSelectAll = true;
                }
            }    
        },
        created() {
            if(this.autoLoad) this.getData();
        },
        methods: {
            calcCanSelectAll() {
                let pass = false;
                for(let i = 0; i < this.item.children.length; i++) {
                    if(!this.item.children[i]['children'] || this.item.children[i]['children'].length == 0) {
                        pass = true;
                        break;
                    }
                }

                return pass && !this.collapse && this.options.type == 'multiple';
            },
            getData() {
                if(this.isGetData) {
                    return;
                }

                // todo...异步加载
                if(this.item.id == 1) {
                    let children = [
                            {
                                id: 11,
                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                name: '党办sub',
                                children: [

                                ]
                            },
                            {
                                id: 12,
                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                name: '党办sub2',
                                children: [

                                ]
                            },
                        ];
                    this.$emit('update-children', [this.index], children);
                }

                if(this.item.id == 11) {
                    let children = [
                                    {
                                        id: 112,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final2',
                                    },
                                    {
                                        id: 111,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final1',
                                        children: []
                                    },
                                    {
                                        id: 113,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final3',
                                    },
                    ]

                    this.$emit('update-children', [this.index], children);
                }

                if(this.item.id == 111) {
                    let children = [
                                    {
                                        id: 1111,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final1-sub',
                                        children: []
                                    },
                                    {
                                        id: 1112,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final12-sub',
                                    },
                                    {
                                        id: 1113,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办final3-sub',
                                    },
                    ]

                    this.$emit('update-children', [this.index], children);
                }

                this.isGetData = true; // 标记已加载数据
            },
            updateChildren(index, children) {
                index.unshift(this.index);
                this.$emit('update-children', index, children);
            },
            itemStyle(item) {
                let style = {
                    height: this.options.itemHeight,
                    paddingLeft: 10 + (this.options.levelPadding * this.level) + 'px',
                };
                if(this.itemSelected.includes(item.id)) {
                    style.backgroundColor = this.options.color
                }
                return style;
            },
            onClass(item) {
                return {
                    on: this.itemSelected.includes(item.id)
                }
            },
            selectItemAll(item, allChecked) {
                this.$emit('select-all', item, allChecked);
            },
            select(item) {
                this.$emit('select', item);
            },
        },
        components: {
            Checkbox,
        }
    }
</script>

<style scoped lang="less">
    .component-container--choose-depart-item{
        .item-wrapper{
            position: relative;
            .action{
                z-index: 9;
                position: absolute;
                margin-left: 10px;
                font-weight: normal;
                right: 36px;
                top: 0;
                height: 100%;
            }
        }
        .item{
            position: relative;
            cursor: pointer;
            height: 35px;
            padding: 0 10px;
            border-bottom: 1px solid #eee;
            font-weight: bold;
            img{
                width: 15px;
                height: 15px;
                border-radius: 4px;
                margin-right: 10px;
            }
            &::after{
                position: absolute;
                content: '';
                width: 16px;
                height: 16px;
                background: url('../../static/icon-down.png') no-repeat center center;
                background-size: 100% 100%;
                right: 10px;
            }
            &.on::after{
                transform: rotate(180deg);
            }
        }

        .final-item{
            cursor: pointer;
            height: 35px;
            padding: 0 10px;
            border-bottom: 1px solid #eee;
            img{
                width: 15px;
                height: 15px;
                border-radius: 4px;
                margin-right: 10px;
            }
            &.on{
                color: #fff;
                background: url(../../static/icon-gou.png) no-repeat calc(100% - 10px) center;
                background-color: #67C23A;
                background-size: 16px;
            }
        }

        // transtion
        .fade-enter-active, .fade-leave-active {
            transition: opacity 0.25s ease;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
    }
</style>