<template>
    <div class="component-container--choose-person-depart">
        <div v-for="(item, index) in dataCopy" :key="index">
            <div flex="cross:center" class="item" :style="style" :class="onClass(item)" @click="toggleOnClass(item)">
                <img :src="item.icon" alt="">
                <span>{{item.name}}</span>
            </div>
            <transition name="fade">
                <div class="choose-person-depart-sub" v-show="showSub(item)">
                    <ChoosePersonDepart v-if="item.children && item.children.length > 0" :data="item.children" :level="nextLevel" :user-selected="userSelected" @select-user="selectUser" @select-user-all="selectUserAll" :options="options"></ChoosePersonDepart>
                    <ChoosePersonUser v-else-if="item.users" :data="item.users" :data-selected="userSelected" @select="addUser" @select-all="addAllUser" :level="nextLevel" :options="options"></ChoosePersonUser>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import ChoosePersonUser from './ChoosePersonUser.vue'

    export default {
        name: "ChoosePersonDepart",
        props: {
            data: {
                type: Array,
                default() {
                    return [];
                },
            },
            userSelected: {
                type: Array,
                required: true,
            },
            level: {
                type: Number,
                default: 0
            },
            options: {
                type: Object,
                default() {
                    return {
                        color: '#52994F',
                    }
                }
            }
        },
        data() {
            return {
                dataCopy: (() => { return this.data })(),
                selectedDepart: []
            }
        },
        computed: {
            nextLevel() {
                return this.level + 1;
            },
            style() {
                return {
                    paddingLeft: 10 + (this.options.levelPadding * this.level) + 'px',
                    height: this.options.itemHeight
                }
            },
        },
        methods: {
            selectUser(user) {
                this.$emit('select-user', user);  
            },
            selectUserAll(data, allChecked) {
                this.$emit('select-user-all', data, allChecked);  
            },
            addUser(user) {
                this.$emit('select-user', user);
            },
            addAllUser(data, allChecked) {
                this.$emit('select-user-all', data, allChecked);
            },
            showSub(item) {
                return this.selectedDepart.includes(item.id);
            },
            onClass(item) {
                return {
                    on: this.selectedDepart.includes(item.id)
                }
            },
            toggleOnClass(item) {
                // todo 可在此做异步请求数据
                if(item.id == 1) {
                    item.children = [
                            {
                                id: 11,
                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                name: '党办sub',
                                children: [
                                    {
                                        id: 111,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办sub2',
                                        users: [
                                            {
                                                id: 1111001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '张三copy'
                                            },
                                            {
                                                id: 1112001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '李四2'
                                            },
                                            {
                                                id: 1113001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '王五2'
                                            },
                                        ]
                                    },
                                ]
                            },
                        ];
                }
                if(item.id == 2) {
                    item.users = [
                                    {
                                        id: 2221001,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '张三222'
                                    },
                                    {
                                        id: 2222001,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '李四222'
                                    },
                                    {
                                        id: 2223001,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '王五222'
                                    },
                                ]
                }
                if(item.id == 3) {
                    item.children = [
                                    {
                                        id: 333,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '胸外科',
                                        users: [
                                            {
                                                id: 3331001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '张三333'
                                            },
                                            {
                                                id: 3332001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '李四333'
                                            },
                                            {
                                                id: 3333001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '王五333'
                                            },
                                        ]
                                    },
                                ]
                }




                let pos = this.selectedDepart.indexOf(item.id);
                if(pos > -1) { // 已存在 移除
                    this.selectedDepart.splice(pos, 1);
                } else {
                    this.selectedDepart.push(item.id);
                }
            }
        },
        components: {
            ChoosePersonUser
        }
    }
</script>

<style scoped lang="less">
    .component-container--choose-person-depart{
        .item{
            position: relative;
            cursor: pointer;
            height: 35px;
            padding: 0 10px;
            border-bottom: 1px solid #eee;
            font-weight: bold;
            img{
                width: 15px;
                height: 15px;
                border-radius: 4px;
                margin-right: 10px;
            }
            &::after{
                position: absolute;
                content: '';
                width: 16px;
                height: 16px;
                background: url('../../static/icon-down.png') no-repeat center center;
                background-size: 100% 100%;
                right: 10px;
            }
            &.on::after{
                transform: rotate(180deg);
            }
        }

        // transtion
        .fade-enter-active, .fade-leave-active {
            transition: opacity 0.25s ease;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
    }
</style>