import { render, staticRenderFns } from "./ChoosePerson.vue?vue&type=template&id=32384e54&scoped=true"
import script from "./ChoosePerson.vue?vue&type=script&lang=js"
export * from "./ChoosePerson.vue?vue&type=script&lang=js"
import style0 from "./ChoosePerson.vue?vue&type=style&index=0&id=32384e54&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32384e54",
  null
  
)

export default component.exports