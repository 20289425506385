<template>
    <div class="component-container" flex="dir:top main:right">
        <div class="mask">
            <div class="wrapper">
                <div class="title">{{title}}</div>
                <ul class="root">
                    <CascaderItem v-for="(v, i) in options" :key="i" :item="v" :pos="i + ''" :selected-path="path" :multiple="multiple" :color="color" @path-remove="removePath" @path-add="addPath"></CascaderItem>
                </ul>
                <div class="actions">
                    <div class="action-cancel" @click="cancel">取消</div>
                    <div class="action-ok" :style="'background-color:' + color" @click="confirm">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CascaderItem from './CascaderItem';

    export default {
        name: "Cascader",
        // emits: ['confirm'],
        props: {
            // options: [
            //     {
            //         text: '',
            //         value: '',
            //         children: [

            //         ]
            //     }
            // ],
            // 选项数据
            options: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                default: '请选择'
            },
            selectedPath: {
                type: Array,
                default() {
                    return [];
                }
            },
            // 是否多选
            multiple: {
                type: Boolean,
                default: false
            },
            // 选中颜色
            color: {
                type: String,
                default: '#2196f3'
            },
        },
        data() {
            return {
                path: Array.from(this.selectedPath)
            }
        },
        computed: {

        },
        created() {

        },
        methods: {
            calcSelect(path) {
                if(this.isInPath(path)) {
                    return {
                        color: this.color
                    }
                }

                return {};
            },
            isInPath(path) {
                for(let i = 0; i < this.path.length; i++) {
                    var regText = '(^' + path + '\\.)|(^'+ path +'$)';
                    var reg = new RegExp(regText);
                    if(reg.test(this.path[i])) {
                        return true;
                    }
                }

                return false;
            },
            removePath(path) {
                if(!this.multiple) {
                    this.path = path.lastIndexOf('.') > 0 ? [path.substr(0, path.lastIndexOf('.'))] : [];
                    return;
                }
                for(let i = this.path.length - 1; i >= 0; i--) {
                    var regText = '(^' + path + '\\.)|(^'+ path +'$)';
                    var reg = new RegExp(regText);
                    if(reg.test(this.path[i])) {
                        this.path.splice(i, 1);
                    }
                }

                let arr = path.split('.');
                if(arr.length >= 2) {
                    let parentPath = path.substr(0, path.lastIndexOf('.'));
                    if(!this.isInPath(parentPath)) {
                        this.path.push(parentPath);
                    }
                }
            },
            addPath(path) {
                if(!this.multiple) {
                    this.path = [path];
                    return;
                }
                if(path.indexOf('.') > -1) {
                    var prev = path.substr(0, path.lastIndexOf('.'))
                    var idx = this.path.indexOf(prev);
                    if(idx > -1) {
                        this.path.splice(idx, 1);
                    }
                }

                this.path.push(path);
            },
            clickItem(path) {
                if(this.isInPath(path)) {
                    this.removePath(path);
                } else {
                    this.addPath(path);
                }
            },
            cancel() {
                this.$emit('cancel');
            },
            confirm() {
                let data = [];
                this.path.forEach((v, i) => {
                    let arr = v.split('.');
                    let opts = this.options;
                    let findArr = [];
                    arr.forEach(v2 => {
                        opts = opts[v2];
                        findArr.push(opts.value);
                        opts = opts.children;
                    })
                    data.push(findArr);
                });
                this.$emit('confirm', this.multiple ? data : (data[0] ? data[0] : []));
            }
        },
        components: {
            CascaderItem
        }
    }
</script>

<style scoped lang="less">
    .component-container{
        position: fixed;
        z-index: 998;
        left: 0;
        top: 0;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgba(0, 0, 0, .6);
        user-select: none;
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .mask{
            width: 100%;
            flex: 1;
            display: flex;
        }
        .wrapper{
            background-color: #fff;
            margin: auto;
            padding: 30px;
            border-radius: 4px;
            max-height: 80%;
            overflow-y: auto;
            min-width: 300px;
            .title {
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .actions{
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                user-select: none;
                .action-ok {
                    position: relative;
                    padding: 5px 12px;
                    border-radius: 2px;
                    cursor: pointer;
                    color: #fff;
                    &:hover::before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 5%)
                    }
                }
                .action-cancel {
                    position: relative;
                    color: #f2f2f2;
                    padding: 5px 12px;
                    border-radius: 2px;
                    cursor: pointer;
                    background-color: #ccc;
                    margin-right: 10px;
                    &:hover::before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 5%)
                    }
                }
            }
        }
    }
</style>