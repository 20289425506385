<template>
    <div class="component-container" flex="dir:top">
        <div class="search" flex="cross:center" :style="headerStyle">
            <div class="input-wrap">
                <input type="text" placeholder="请输入部门或人员姓名" v-model="keywords">
                <img v-show="keywords" @click="clearSearch" src="../../static/icon-input-clear.png" alt="">
            </div>
            <span class="btn-search" :style="bgColor" flex="cross:center">搜索</span>
        </div>
        <div class="body">
            <ChoosePersonDepart :data="departs" :user-selected="selectedUsers" @select-user="addUser" @select-user-all="addAllUser" :options="opts"></ChoosePersonDepart>
            <ChoosePersonUser :data="users" :data-selected="selectedUsers" @select="addUser" @select-all="addAllUser" :options="opts"></ChoosePersonUser>
        </div>
        <div class="footer" flex="main:justify cross:center" :style="footerStyle">
            <div class="btn btn-cancel" flex="main:center cross:center">取消</div>
            <div class="btn btn-confirm" flex="main:center cross:center" :style="bgColor">确定</div>
        </div>
    </div>
</template>

<script>
    import ChoosePersonDepart from './ChoosePersonDepart.vue'
    import ChoosePersonUser from './ChoosePersonUser.vue'

    export default {
        name: "ChoosePerson",
        props: {
            options: {
                type: Object,
                default() {
                    return {

                    }
                }
            }
        },
        computed: {
            opts() {
                let optDefault = {
                    color: '#52994F', // 风格颜色
                    headerHeight: '45px',
                    itemHeight: '35px',
                    footerHeight: '45px',
                    levelPadding: 15,
                    type: 'multiple', // single (单选), multiple (多选)
                }
                return Object.assign({}, optDefault, this.options);
            },
            bgColor() {
                return {
                    backgroundColor: this.opts.color
                }
            },
            headerStyle() {
                return {
                    height: this.opts.headerHeight
                }
            },
            footerStyle() {
                return {
                    height: this.opts.footerHeight
                }
            }
        },
        data() {
            return {
                keywords: '',
                departs: [
                    {
                        id: 1,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '党办',
                        children: [
                            {
                                id: 11,
                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                name: '党办sub',
                                children: [
                                    {
                                        id: 111,
                                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                        name: '党办sub2',
                                        users: [
                                            {
                                                id: 1111001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '张三copy'
                                            },
                                            {
                                                id: 1112001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '李四2'
                                            },
                                            {
                                                id: 1113001,
                                                icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                                                name: '王五2'
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        id: 2,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '信息科'
                    },
                    {
                        id: 3,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '放射科'
                    },
                ],
                users: [
                    {
                        id: 1001,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '张三'
                    },
                    {
                        id: 2001,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '李四'
                    },
                    {
                        id: 3001,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '王五'
                    },
                ],
                selectedUsers: []
            }    
        },
        created() {
            this.getData();
        },
        mounted() {
            // 防止滚动穿透
            document.body.style.overflow = 'hidden';
        },
        beforeDestroy() {
            // 还原防止滚动穿透
            document.body.style.overflow = 'visible';
        },
        methods: {
            getData() {
                this.departs = [
                    {
                        id: 1,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '党办'
                    },
                    {
                        id: 2,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '信息科'
                    },
                    {
                        id: 3,
                        icon: 'https://img1.baidu.com/it/u=1302568142,2160646092&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                        name: '放射科'
                    },
                ];
            },
            clearSearch() {
                this.keywords = '';
            },
            addUser(user) {
                let pos = this.selectedUsers.indexOf(user.id);
                if(pos > -1) { // 已存在 移除
                    this.selectedUsers.splice(pos, 1);
                } else {
                    if(this.opts.type == 'single') {
                        this.selectedUsers = [user.id];
                    } else if(this.opts.type == 'multiple') {
                        this.selectedUsers.push(user.id);   
                    }
                }
            },
            addAllUser(data, allChecked) {
                data.forEach((v, i) => {
                    if(allChecked) { // 全选
                        let pos = this.selectedUsers.indexOf(v.id);
                        if(pos == -1) {
                            this.selectedUsers.push(v.id);
                        }
                    } else { // 全不选
                        let pos = this.selectedUsers.indexOf(v.id);
                        if(pos > -1) {
                            this.selectedUsers.splice(pos, 1);
                        }
                    }
                }); 
            },
        },
        components: {
            ChoosePersonDepart,
            ChoosePersonUser,
        }
    }
</script>

<style scoped lang="less">
    .component-container{
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 999999;
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        .search{
            height: 45px;
            .input-wrap{
                flex: 1;
                height: 100%;
                position: relative;
                input{
                    width: 100%;
                    height: 100%;
                    font-size: 16px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    border: solid 1px #eee;
                    outline: none;
                    border-radius: 4px;
                }
                img{
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    width: 16px;
                    height: 16px;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }
            .btn-search{
                background-color: #67C23A;
                color: #fff;
                margin-left: 10px;
                height: 100%;
                padding: 0 10px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
        .body{
            flex: 1;
            border: solid 1px #eee;
            margin-top: 10px;
            border-radius: 4px;
            font-size: 14px;
            overflow-y: scroll;
        }
        .footer{
            height: 45px;
            margin-top: 10px;
            .btn{
                width: 49%;
                height: 100%;
                border-radius: 4px;
                font-size: 16px;
                cursor: pointer;
                &.btn-confirm{
                    background-color: #67C23A;
                    color: #fff;
                }
                &.btn-cancel{
                    background-color: #eee;
                    color: #999;
                }        
            }
        }
    }
</style>