<template>
    <div class="component-container--choose-person-user">
        <div flex="cross:center" :style="actionStyle" v-if="options.type == 'multiple'">
            <Checkbox v-model="selectAll" label="全选" :color="options.color"></Checkbox>
        </div>
        <div flex="cross:center" class="item" :class="selectedClass(item)" v-for="(item, index) in data" :key="index" @click="addUser(item)" :style="style(item)">
            <img :src="item.icon" alt="">
            <span>{{item.name}}</span>
        </div>
    </div>
</template>

<script>
    import Checkbox from '../Checkbox.vue';

    export default {
        name: "ChoosePersonUser",
        props: {
            data: {
                type: Array,
                default() {
                    return [];
                },
            },
            dataSelected: {
                type: Array,
                required: true
            },
            level: {
                type: Number,
                default: 0
            },
            options: {
                type: Object,
                default() {
                    return {
                        color: '#52994F',
                    }
                }
            }
        },
        data() {
            return {
                selectAll: false,
                canEmitSelectAll: true,
            }
        },
        computed: {
            actionStyle() {
                let style = {
                    height: this.options.itemHeight,
                    paddingLeft: 10 + (this.options.levelPadding * this.level) + 'px',
                    borderBottom: '1px solid #eee'
                };
                return style;
            }
        },
        watch: {
            selectAll(newVal, oldVal) {
                if(this.canEmitSelectAll) {
                    this.$emit('select-all', this.data, newVal);   
                } else {
                    this.canEmitSelectAll = true;
                }
            }    
        },
        methods: {
            selectedClass(item) {
                return {
                    on: this.dataSelected.includes(item.id)
                }
            },
            style(item) {
                let style = {
                    height: this.options.itemHeight,
                    paddingLeft: 10 + (this.options.levelPadding * this.level) + 'px',
                };
                if(this.dataSelected.includes(item.id)) {
                    style.backgroundColor = this.options.color
                }
                return style;
            },
            addUser(user) {
                this.$emit('select', user);

                if(this.options.type == 'multiple') {
                    this.canEmitSelectAll = false;
                    if(this.isSelectAll()) {
                        this.selectAll = true;
                    } else {
                        if(this.selectAll) {
                            this.selectAll = false;
                        } else {
                            this.canEmitSelectAll = true;
                        }
                    }   
                }
            },
            isSelectAll() {
                for(let i = 0; i < this.data.length; i++) {
                    let tmp = this.data[i]['id'];
                    if(!this.dataSelected.includes(tmp)) {
                        return false;
                    }
                }

                return true;
            }
        },
        components: {
            Checkbox
        }
    }
</script>

<style scoped lang="less">
    .component-container--choose-person-user{
        .item{
            cursor: pointer;
            height: 35px;
            padding: 0 10px;
            border-bottom: 1px solid #eee;
            img{
                width: 15px;
                height: 15px;
                border-radius: 4px;
                margin-right: 10px;
            }
            &.on{
                color: #fff;
                background: url(../../static/icon-gou.png) no-repeat calc(100% - 10px) center;
                background-color: #67C23A;
                background-size: 16px;
            }
        }
    }
</style>