<template>
    <li class="component-item-container">
        <div @click="clickItem(pos)" class="wrapper-label">
            <span class="label" :style="calcSelect(pos)" :class="calcTextClass(pos)"><span class="text">{{item.text}}</span><span class="arrow" :class="calcArrowClass(pos)"><svg t="1669552077198" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2661" width="10" height="10" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M761.6 489.6l-432-435.2c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l416 416-416 425.6c-9.6 9.6-9.6 25.6 0 35.2s25.6 9.6 35.2 0l432-441.6C771.2 515.2 771.2 499.2 761.6 489.6z" p-id="2662" :fill="isInPath(pos) ? color : ''"></path></svg></span>
                </span>
        </div>
        <ul v-if="item.children && item.children.length > 0" :class="calcShowClass(pos)">
            <CascaderItem v-for="(v2, i2) in item.children" :key="i2" :item="v2" :pos="pos + '.' + i2" :selected-path="path" :multiple="multiple" :color="color" @path-remove="removePath" @path-add="addPath"></CascaderItem>
        </ul>
    </li>
</template>

<script>
    export default {
        name: "CascaderItem",
        // emits: ['xxx'],
        props: {
            // 当前path位置
            pos: {
                type: String,
                required: true
            },
            /*
            item形如:
                {
                    text: '',
                    value: '',
                    children: [

                    ]
                }
            */
            item: {
                type: Object,
                required: true
            },
            selectedPath: {
                type: Array,
                default() {
                    return [];
                }
            },
            // 是否多选
            multiple: {
                type: Boolean,
                default: false
            },
            // 选中颜色
            color: {
                type: String,
                default: '#2196f3'
            },
        },
        data() {
            return {

            }
        },
        computed: {
            path() {
                return Array.from(this.selectedPath);
            }
        },
        created() {

        },
        methods: {
            calcArrowClass(path) {
                if(!this.item.children || this.item.children.length == 0) {
                    return {
                        none: true
                    }
                }
                if(this.isInPath(path)) {
                    return {
                       open : true
                    }
                }

                return {
                    close: true
                };
            },
            calcTextClass(path) {
                let styles = {
                    'animate__animated': true,
                }

                if(this.isInPath(path)) {
                    styles['animate__bounceIn'] = true;
                } else {
                    styles['animate__swing'] = true;
                }

                return styles;
            },
            calcShowClass(path) {
                if(this.isInPath(path)) {
                    return {
                        on: true,
                        'animate__animated': true,
                        'animate__bounceIn': true,
                    }
                }

                return {};
            },
            calcSelect(path) {
                if(this.isInPath(path)) {
                    return {
                        color: this.color
                    }
                }

                return {};
            },
            isInPath(path) {
                for(let i = 0; i < this.path.length; i++) {
                    var regText = '(^' + path + '\\.)|(^'+ path +'$)';
                    var reg = new RegExp(regText);
                    if(reg.test(this.path[i])) {
                        return true;
                    }
                }

                return false;
            },
            removePath(path) {
                this.$emit('path-remove', path);
            },
            addPath(path) {
                this.$emit('path-add', path);
            },
            clickItem(path) {
                if(this.isInPath(path)) {
                    this.removePath(path);
                } else {
                    this.addPath(path);
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .component-item-container {
        list-style: none;
        margin: 0;
        padding: 0 5px;
        padding-left: 15px;
        .wrapper-label {
            padding: 5px;
            cursor: pointer;
            &:hover{
                //opacity: 0.7;
                background-color: #f2f2f2;
            }
        }
        ul{
            margin: 0;
            padding: 0;
            display: none;
            &.on{
                display: block;
            }
        }
        .label{
            user-select: none;
            display: inline-block;
            .text{
                cursor: pointer;
                padding: 1px 2px; 
            }
            .text:hover{
                background-color: #f2f2f2;
                // border-radius: 4px;
            }
        }
        .arrow{
            color: #9e9e9e !important;
            display: inline-block;
        }
        .none{
            display: none;
        }
        .close{
            transform: rotate(90deg) translatey(-5px);
        }
        .open{
            transform: rotate(-90deg) translatey(5px);
        }
    }
</style>